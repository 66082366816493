import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";

import { SavePostSaleDiscountResponseValue } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

type Props = {
  onClose: () => void;
  responseValue: SavePostSaleDiscountResponseValue;
};

export const ImportedPostSaleDiscountsDialog: React.FC<Props> = ({ onClose, responseValue }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const importedDiscounts = parseResponseToImportedDiscounts(responseValue);

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingsList.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (discount: ImportedDiscount): RowCellContent[] => {
    return [
      {
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: discount.error ? theme.colors.dangerBackground : theme.colors.primaryLight,
            }}
          ></div>
        ),
      },
      {
        cellCss: { color: discount.error ? theme.colors.danger : theme.colors.primary, fontWeight: 700 },
        element: discount.customerName,
      },
      {
        cellCss: { color: "black" },
        element: discount.discount?.toFixed(2) ?? "-",
      },
      {
        cellCss: { color: theme.colors.danger },
        element: <span css={{ textWrap: "nowrap" }}>{discount.error}</span>,
      },
    ];
  };

  return (
    <Dialog
      placement={isMobile ? "top" : "center"}
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "90vw", height: "min(700px, 90vh)" }}
      header="Zaimportowani wykonawcy"
    >
      <div css={{ overflow: "auto", margin: "-1rem -2rem", padding: "1rem 2rem", height: "calc(100% + 2rem)" }}>
        <Table columns={tableColumns}>
          <TableBody
            rows={importedDiscounts.map((discount) => ({
              cellsContent: getTableRowCellsContent(discount),
            }))}
          />
        </Table>
      </div>
    </Dialog>
  );
};

const tableHeadingsList = ["Nazwa klienta", "Rabat posprzedażowy", "Błąd"];

type ImportedDiscount = {
  customerName: string;
  discount: Decimal | null;
  error?: string;
};

const parseResponseToImportedDiscounts = (responseValue: SavePostSaleDiscountResponseValue) => {
  const importedDiscounts: ImportedDiscount[] = [];

  for (const addedDiscount of responseValue.addedDiscounts) {
    importedDiscounts.push({
      customerName: addedDiscount.customerName,
      discount: addedDiscount.discount,
      error: undefined,
    });
  }

  for (const clientNotFound of responseValue.clientNotFound) {
    importedDiscounts.push({
      customerName: clientNotFound.customerName,
      discount: clientNotFound.discount,
      error: "Klient nie istnieje",
    });
  }

  for (const clientIsNotPayer of responseValue.clientIsNotPayer) {
    importedDiscounts.push({
      customerName: clientIsNotPayer.customerName,
      discount: clientIsNotPayer.discount,
      error: "Klient nie jest płatnikiem",
    });
  }

  return importedDiscounts;
};
