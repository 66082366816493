import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const postSaleDiscountSerialzier = Serializers.object({
  customerId: Serializers.string,
  customerName: Serializers.string,
  discount: Serializers.decimal.nullable(),
});

export const savePostSaleDiscountSerialzier = Serializers.object({
  addedDiscounts: postSaleDiscountSerialzier.array(),
  clientNotFound: Serializers.object({
    customerName: Serializers.string,
    discount: Serializers.decimal.nullable(),
  }).array(),
  clientIsNotPayer: Serializers.object({
    customerName: Serializers.string,
    discount: Serializers.decimal.nullable(),
  }).array(),
});

export type SavePostSaleDiscountResponseValue = SerializerValue<typeof savePostSaleDiscountSerialzier>;

export type PostSaleDiscount = SerializerValue<typeof postSaleDiscountSerialzier>;

export const regionTargetSerialzier = Serializers.object({
  month: Serializers.month,
  targets: Serializers.record(Serializers.decimal.nullable(), Serializers.string),
});

export type RegionTarget = SerializerValue<typeof regionTargetSerialzier>;

export const margins = {
  postSaleDiscountQuery: HttpAction({
    path: "/postSaleDiscount",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: postSaleDiscountSerialzier.array(),
  }),

  savePostSaleDiscount: HttpAction({
    path: "/postSaleDiscount",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      customerName: Serializers.string,
      discount: Serializers.decimal.nullable(),
    }).array(),
    valueSerializer: savePostSaleDiscountSerialzier,
  }),

  saveRegionTarget: HttpAction({
    path: "/regionTarget",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: regionTargetSerialzier.array(),
    valueSerializer: regionTargetSerialzier.array(),
  }),

  regionTargetQuery: HttpAction({
    path: "/regionTarget",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: regionTargetSerialzier.array(),
  }),
};
