import { useTheme } from "@emotion/react";
import { ProposalDetail } from "@megarax/crm-contracts";
import { useProfilesDictionary, UserProfile } from "@megarax/iam-webapp";
import Decimal from "decimal.js";
import _ from "lodash";
import { useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { useQueryClient } from "react-query";
import tinycolor from "tinycolor2";

import { Avatar } from "@megaron/dash-avatar";
import { DescriptionField } from "@megaron/dash-description-list";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button, DecimalField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { SelectDialog } from "@megaron/dash-select";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useToast } from "@megaron/dash-toast";
import { UserDoc } from "@megaron/docs-contracts";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  customerUuid: string;
  postSaleDiscount: Decimal | null | undefined;
};

export const PayerPricingRules: React.FC<Props> = ({ customerUuid, postSaleDiscount }) => {
  const theme = useTheme();

  const queryClient = useQueryClient();

  const pricingRulesQuery = useClientManager("legacyMegarax")
    .retrievePayer()
    .useQuery({ customerUuid: customerUuid as Uuid });

  const { profiles: getProfiles } = useProfiles();

  const profiles = useProfilesDictionary(
    _.uniqBy(
      pricingRulesQuery?.data?.proposals.map((proposal) => proposal.proposedBy),
      "id",
    ),
  );

  const creatorsProfiles = profiles
    ? getProfiles(Object.entries(profiles).map(([key, value]) => (value as UserProfile).displayedName))
    : undefined;

  const proposalDialog = useDialogRoute("/zaproponuj-zmiane", ({ onClose }) => (
    <PricingRulesProposalDialog
      onClose={onClose}
      customerUuid={customerUuid}
      onSuccess={() => {
        queryClient.invalidateQueries(pricingRulesQuery.key);
      }}
    />
  ));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader
        isHr
        css={{ margin: 0 }}
        actions={
          <button
            css={{
              padding: "0.25rem",
              border: "none",
              cursor: "pointer",
              margin: 0,
              background: "none",
              display: "flex",
              alignItems: "center",
            }}
            onClick={proposalDialog.open}
          >
            <MdOutlineEdit size={18} color={theme.colors.primary} />
          </button>
        }
      >
        Wrunki handlowe
      </SectionHeader>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%" }}>
        <QuerySkeleton query={pricingRulesQuery} height="160px">
          {(pricingRules) => (
            <PricingRulesDetails
              comment={pricingRules?.otherTerms}
              discount={pricingRules?.baseDiscount}
              priceListUuid={pricingRules?.priceList?.uuid}
            />
          )}
        </QuerySkeleton>
        {/* <DescriptionField label="Rabat posprzedażowy">
          {postSaleDiscount ? `${postSaleDiscount?.toFixed(2)}%` : "-"}
        </DescriptionField> */}
      </div>
      <QuerySkeleton query={pricingRulesQuery} height="160px">
        {(pricingRules) =>
          pricingRules?.proposals.length > 0 && (
            <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <h3 css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary, margin: 0 }}>
                Propozycje zmian
              </h3>
              {pricingRules?.proposals.map((proposal, i) => {
                const creatorData = profiles?.[proposal.proposedBy.id];
                const creatorProfile = creatorsProfiles?.find(
                  (profile) => profile.email === creatorData?.displayedName,
                );

                return (
                  <ProposalTile
                    proposal={proposal}
                    creator={creatorProfile}
                    key={`pricing-rules-change-proposition-${i}`}
                    onChange={() => queryClient.invalidateQueries(pricingRulesQuery.key)}
                    customerUuid={customerUuid as Uuid}
                  />
                );
              })}
            </div>
          )
        }
      </QuerySkeleton>
      {proposalDialog.element}
    </div>
  );
};

const PricingRulesDetails = ({
  priceListUuid,
  discount,
  comment,
}: {
  priceListUuid?: string;
  discount: Decimal;
  comment: string;
}) => {
  const priceListQuery = useClientManager("legacyMegarax")
    .priceList()
    .useQuery({ priceListUuid: priceListUuid || "" }, { enabled: !!priceListUuid });

  if (!priceListUuid) {
    return <PricingRulesContent priceListName="Brak" discount={new Decimal(0)} />;
  }

  return (
    <QuerySkeleton query={priceListQuery}>
      {(priceList) => <PricingRulesContent priceListName={priceList.name} discount={discount} comment={comment} />}
    </QuerySkeleton>
  );
};

const PricingRulesContent: React.FC<{ priceListName: string; discount: Decimal; comment?: string }> = ({
  priceListName,
  discount,
  comment,
}) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%" }}>
      <div css={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
        <DescriptionField label="Cennik">{priceListName}</DescriptionField>
        <DescriptionField label="Rabat podstawowy">{discount.mul(100).toFixed(0)}%</DescriptionField>
      </div>
      {comment && <DescriptionField label="Komentarz">{comment}</DescriptionField>}
    </div>
  );
};

const PricingRulesProposalDialog: React.FC<{ onClose: () => void; customerUuid: string; onSuccess: () => void }> = ({
  onClose,
  customerUuid,
  onSuccess,
}) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const [priceListUuid, setPriceListUuid] = useState<string>();
  const [discount, setDiscount] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  const proposeChangeMutation = useClientManager("legacyMegarax").proposePayerRuleChange().useMutation();

  const priceListsQuery = useClientManager("legacyMegarax").priceLists().useQuery({});

  const onProposeChange = () => {
    proposeChangeMutation.mutate(
      {
        customerUuid: customerUuid as Uuid,
        priceList: { uuid: priceListUuid as Uuid },
        baseDiscount: new Decimal(Number(discount) / 100),
        otherTerms: comment,
      },
      {
        onSuccess: () => {
          onSuccess();
          onClose();
        },
        onError: () => {
          toast.error("Nie udało się dodać propozycji zmiany");
        },
      },
    );
  };

  return (
    <Dialog
      onClose={onClose}
      header="Zaproponuj zmianę"
      css={{ width: isMobile ? "100%" : "400px" }}
      placement={isMobile ? "top" : "center"}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.75rem", width: "100%" }}>
        <SelectDialog
          variant="single-select"
          label="Cennik"
          options={priceListsQuery?.data?.items.map((item) => ({ label: item.name, value: item.uuid }))}
          onSelect={(option) => {
            setPriceListUuid(option?.value ?? "");
          }}
        />
        <DecimalField label="Rabat podstawowy" value={discount} onChange={setDiscount} />
        <TextField label="Komentarz" value={comment} onChange={setComment} multiline />
        <Button css={{ alignSelf: "flex-end" }} onClick={onProposeChange}>
          Zaproponuj zmianę
        </Button>
      </div>
    </Dialog>
  );
};

const ProposalTile: React.FC<{
  proposal: ProposalDetail;
  creator?: UserDoc;
  customerUuid: Uuid;
  onChange: () => void;
}> = ({ proposal, creator, customerUuid, onChange }) => {
  const theme = useTheme();

  const toast = useToast();

  const priceListQuery = useClientManager("legacyMegarax")
    .priceList()
    .useQuery({ priceListUuid: proposal.priceList?.uuid || "" });

  const approveProposalMutation = useClientManager("legacyMegarax").approvePayerProposal().useMutation();

  const rejectProposalMutation = useClientManager("legacyMegarax").rejectPayerProposal().useMutation();

  const onApprove = () => {
    approveProposalMutation.mutate(
      { customerUuid: customerUuid, proposalUuid: proposal.uuid as Uuid },
      {
        onSuccess: () => {
          onChange();
        },
        onError: () => {
          toast.error("Nie udało się zaakceptować propozycji zmiany");
        },
      },
    );
  };

  const onReject = () => {
    rejectProposalMutation.mutate(
      { customerUuid: customerUuid, proposalUuid: proposal.uuid as Uuid },
      {
        onSuccess: () => {
          onChange();
        },
        onError: () => {
          toast.error("Nie udało się odrzucić propozycji zmiany");
        },
      },
    );
  };

  return (
    <QuerySkeleton query={priceListQuery} height="140px">
      {(priceList) => (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            padding: "0.75rem",
            borderRadius: theme.smallBorderRadius,
            border: `1px solid ${tinycolor("rgb(0, 0, 0)").setAlpha(0.1)}`,
            background: "white",
            gap: "0.25rem",
          }}
        >
          <span css={{ color: theme.colors.primary, fontSize: "0.875rem" }}>
            <strong>Cennik</strong> - {priceList.name}
          </span>
          <span css={{ color: theme.colors.primary, fontSize: "0.875rem" }}>
            <strong>Rabat podstawowy</strong> - {proposal.baseDiscount.mul(100).toFixed()}%
          </span>
          <div css={{ display: "flex", gap: "0.5rem" }}>
            <Avatar src={creator?.profilePictureUrl} size="20px" css={{ flexShrink: 0 }} />
            <div css={{ display: "flex", flexDirection: "column" }}>
              <span css={{ fontSize: "0.875rem", color: "black" }}>{creator?.name || proposal.proposedBy.id}</span>
              {proposal.otherTerms && (
                <p css={{ fontSize: "0.75rem", color: theme.colors.secondaryText, margin: 0 }}>{proposal.otherTerms}</p>
              )}
            </div>
          </div>
          <div css={{ display: "flex", justifyContent: "flex-end", gap: "0.25rem" }}>
            <Button color="danger" size="small" onClick={onReject}>
              Odrzuć
            </Button>
            <Button size="small" onClick={onApprove}>
              Zaakceptuj
            </Button>
          </div>
        </div>
      )}
    </QuerySkeleton>
  );
};
